import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { sentenceCase } from 'sentence-case';
import Colors from '../../Colors';
import Notifier from '../../Notifier';
import useIcons from '../../hooks/useIcons';
import { GetBranchOrderVouchers, GetOrderItems, UpdateOrderStatus } from '../../queries';
import { vars } from '../../reactive';
import { unsetDrawerBars } from '../../reactive/actions';
import copyClipBoard from '../../utils/copyClipBoard';
import { getFormattedDate, toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { getKeys } from '../../utils/helpers';
import { ProductOrder } from '../../views/Orders/types';
import { OrderVoucher } from '../../views/Store/Discounts/types';
import { ActionBtn } from '../../views/Store/styled';
import Alert from '../Alert/Alert';
import { MobileCloseButton } from '../DrawerBar/DrawerButtons';
import {
  CallToAction,
  CallToActionContainer,
  Divider,
  DrawerContainer,
  DrawerContent,
  DrawerFooter,
  DrawerLabel,
  DrawerLabelContainer,
  DrawerList,
  DrawerSection,
  DrawerSubLabel,
  PaymentLabel,
  ProductOrderDrawerButtonsContainer
} from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import ProfileActionBar from '../Profile/ProfileActionBar';
import BranchNotes from '../Shared/BranchNotes/BranchNotes';
import { BranchNoteType } from '../Shared/BranchNotes/types';
import { FlexCenterCenter } from '../Shared/Shared';
import { ActivityIndicator } from '../Shared/Spinner';
import DeliveryAddress from './DeliveryAddress';
import ProductOrderDrawerButtons from './ProductOrderDrawerButtons';

const OrderDrawer = ({ data: order }: { data: ProductOrder }) => {
  const icons = useIcons();
  const copyIcon = icons.copy.childImageSharp.gatsbyImageData.images.fallback.src;
  const { data: { getOrderItems: orderItems = [] } = {}, loading, error } = useQuery(GetOrderItems, { variables: { OrderId: order.id } });
  const { data: { getBranchOrderVouchers: orderVouchers = [] } = {} } = useQuery<{ getBranchOrderVouchers: OrderVoucher[] }>(GetBranchOrderVouchers, { variables: { OrderId: order?.id } });
  const [updateOrderStatus, { data: { updateOrderStatus: updatedOrder } = {}, loading: updateStatusLoading, error: updateStatusError }] = useMutation(UpdateOrderStatus);
  const profile = order?.AppUser;

  const totalPrices = orderItems.map(orderItem => {
    if (orderItem?.customizations?.length) {
      const nextPrice = orderItem?.customizations?.reduce(
        (totalCustom, customization) =>
          totalCustom +
          (Number(orderItem?.item?.price || 0) + getKeys(customization?.fields || {}).reduce((extra, customKey) => extra + Number(customization.fields[customKey].price), 0)) * customization.quantity,
        0
      );
      return nextPrice;
    }
    const nextPrice = Number(orderItem.item.price) * orderItem.quantity;
    return nextPrice;
  });
  const total: number = totalPrices.reduce((total, productTotal) => {
    return total + productTotal;
  }, 0);
  const voucher = orderVouchers?.[0]?.voucher;
  const voucherDiscount = voucher?.discount;
  const discount = voucherDiscount?.type === 'percentage' ? `%${voucherDiscount?.amount}` : `${getBranchCurrencySymbol()}${voucherDiscount?.amount}`;

  const isFullyOrPartiallyRefunded = !!order?.Refunds?.length;
  const totalRefunded = order?.Refunds?.reduce((total, refund) => total + Number(refund.amount), 0);

  useEffect(() => {
    if (updatedOrder) {
      unsetDrawerBars();
      Notifier.success({ title: 'Order', message: 'Success' });
    }
  }, [updatedOrder]);

  const renderCart = orderItem => {
    const showDivider = orderItems.length > 1;
    const marginY = showDivider ? 15 : 0;
    const customizations = orderItem?.customizations || [];
    if (customizations?.length) {
      return customizations.map((customization, idx) => {
        const fields = getKeys(customization.fields)
          .map(customKey => `(${customKey}: ${customization.fields[customKey]?.title})`)
          .join(' ')
          .trim();
        return (
          <li key={orderItem?.id + fields}>
            <DrawerLabelContainer marginY={marginY}>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                {orderItem?.item?.name} {fields}
              </DrawerSubLabel>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                x{customization?.quantity}
              </DrawerSubLabel>
            </DrawerLabelContainer>
            {showDivider && <Divider />}
          </li>
        );
      });
    }
    return (
      <li key={orderItem?.id}>
        <DrawerLabelContainer marginY={marginY}>
          <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
            {orderItem?.item?.name}
          </DrawerSubLabel>
          <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
            x{orderItem?.quantity}
          </DrawerSubLabel>
        </DrawerLabelContainer>
        {showDivider && <Divider />}
      </li>
    );
  };

  const handleAcceptOrder = () => {
    updateOrderStatus({ variables: { id: order.id, status: 'CONFIRMED' } });
  };
  const handleRejectOrder = () => {
    Alert.alert({
      title: 'Are you sure?',
      acceptButtonText: 'Yes, Reject',
      denyButtonText: 'No, Cancel',
      description: 'Are you sure you want to cancel/reject?',
      onAccept: () => {
        updateOrderStatus({ variables: { id: order.id, status: 'CANCELED' } });
      }
    });
  };
  const handleMarkAsShippedOrder = () => {
    updateOrderStatus({
      variables: { id: order.id, status: 'OUT_FOR_DELIVERY' }
    });
  };

  const addressCopyText = ['name', 'line1', 'line2', 'postcode', 'city', 'country']
    .map(key => {
      if (key === 'postcode') {
        return order?.delivery?.address.postcode || order?.AppUser?.postcode;
      }
      return order?.delivery?.address[key];
    })
    .filter(val => !!val?.trim() || '')
    .join(', ');
  const itemsCopyText = orderItems.map(orderItem => `${orderItem?.item?.name} x${orderItem?.quantity}`).join(' / ');

  const handleCopy = () => {
    copyClipBoard(
      `${order.number} \t ${itemsCopyText} \t ${addressCopyText} \t ${order?.AppUser?.email} \t ${toReadableDate(order?.createdAt, { isLocale: true })} \t ${getBranchCurrencySymbol()}${
        total || 0
      } \t ${getBranchCurrencySymbol()}${discount || 0} \t ${order?.delivery?.method?.description} \t ${getBranchCurrencySymbol()}${
        Number(order?.total || 0) + Number(order?.delivery?.method?.price || 0)
      }`
    );
  };
  const drawer = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.ORDER_DRAWER)!;

  return (
    <DrawerContainer>
      <DrawerSection paddingT={24} paddingB={20} isFixed flexRow center>
        <MobileCloseButton drawer={drawer} />
        <DrawerLabel>{order.number}</DrawerLabel>
        <ProductOrderDrawerButtonsContainer>
          <ProductOrderDrawerButtons
            {...{
              order: order,
              loading: loading || updateStatusLoading,
              refetch: drawer?.otherData?.refetch
            }}
          />
        </ProductOrderDrawerButtonsContainer>
      </DrawerSection>
      <Divider />
      <DrawerContent bgColor={'#FAFAFA'}>
        <div>
          <DrawerSubLabel weight={600} size={14} marginBottom={12}>
            Ordered by
            <ActionBtn onClick={handleCopy} bgImage={copyIcon} />
          </DrawerSubLabel>
          <ProfileActionBar profile={profile} drawerId={DRAWER_IDS.ORDER_DRAWER} />
          <Divider marginBottom={16} marginTop={16} />
          {!!order?.delivery && <DeliveryAddress address={{ ...order?.delivery?.address, email: order?.AppUser?.email, postcode: order?.delivery?.address.postcode || order?.AppUser?.postcode }} />}
          <Divider marginBottom={16} />
          <DrawerSubLabel weight={600} size={14} marginBottom={6} marginTop={16}>
            Items
          </DrawerSubLabel>
          <DrawerList>
            {loading && (
              <FlexCenterCenter height={200}>
                <ActivityIndicator size={30} color={Colors.primary} />
              </FlexCenterCenter>
            )}
            {!!orderItems?.length && orderItems.map(orderItem => renderCart(orderItem))}
            {!orderItems?.length && <div>Cart is empty!</div>}
          </DrawerList>
          {order?.delivery?.deliver_by && (
            <>
              <Divider marginBottom={16} />

              <DrawerSubLabel weight={600} size={14} marginBottom={6} marginTop={16}>
                Ordered for
              </DrawerSubLabel>
              <DrawerSubLabel weight={600} color={Colors.black} size={16} marginBottom={20}>
                {getFormattedDate({ date: new Date(order.delivery.deliver_by), hideTime: true })}
              </DrawerSubLabel>
            </>
          )}
          <Divider marginBottom={12} marginTop={12} />
          {!!order?.id && <BranchNotes type={BranchNoteType.ORDER} OrderId={order?.id} />}
          <Divider marginTop={12} />
        </div>
        <div>
          <DrawerLabelContainer marginY={15}>
            <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
              {'Subtotal'}
            </DrawerSubLabel>
            <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
              {getBranchCurrencySymbol()}
              {total?.toFixed(2)}
            </DrawerSubLabel>
          </DrawerLabelContainer>
          {!!orderVouchers.length && (
            <DrawerLabelContainer marginY={15}>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                Discount
              </DrawerSubLabel>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                {voucher?.code} -{discount}
              </DrawerSubLabel>
            </DrawerLabelContainer>
          )}
          {!!order?.delivery?.method && (
            <DrawerLabelContainer marginY={15}>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                {order?.delivery?.method?.description}
              </DrawerSubLabel>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                {getBranchCurrencySymbol()}
                {order?.delivery?.method?.price}
              </DrawerSubLabel>
            </DrawerLabelContainer>
          )}
          {Number(order?.credit_used) > 0 && (
            <DrawerLabelContainer marginY={15}>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                Credit Used
              </DrawerSubLabel>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                {order?.credit_used}
              </DrawerSubLabel>
            </DrawerLabelContainer>
          )}
          <DrawerLabelContainer marginY={15}>
            <DrawerSubLabel weight={600} size={14} marginBottom={4}>
              Total Price
            </DrawerSubLabel>
            <DrawerSubLabel weight={700} color={Colors.black} size={22} marginBottom={15}>
              {getBranchCurrencySymbol()}
              {Number(order?.total) + Number(order?.delivery?.method?.price || 0)}
            </DrawerSubLabel>
          </DrawerLabelContainer>
          {isFullyOrPartiallyRefunded && (
            <DrawerLabelContainer marginY={15}>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                {'Total Refunded'}
              </DrawerSubLabel>
              <DrawerSubLabel marginBottom={0} weight={600} color={Colors.black}>
                {getBranchCurrencySymbol()}
                {totalRefunded}
              </DrawerSubLabel>
            </DrawerLabelContainer>
          )}
          <PaymentLabel>{sentenceCase(order?.status)}</PaymentLabel>
        </div>
      </DrawerContent>
      <Divider />
      <DrawerFooter>
        {updateStatusLoading ? (
          <FlexCenterCenter height={200}>
            <ActivityIndicator size={30} color={Colors.primary} />
          </FlexCenterCenter>
        ) : ['REQUESTED', 'DISCOUNT_APPLIED', 'PAYMENT_SUCCEEDED'].includes(order?.status) ? (
          <CallToActionContainer>
            <CallToAction error={updateStatusError} onClick={handleAcceptOrder}>
              Accept Order
            </CallToAction>
            <CallToAction error={updateStatusError} onClick={handleRejectOrder} secondary danger>
              Reject Order
            </CallToAction>
          </CallToActionContainer>
        ) : (
          ['CONFIRMED'].includes(order?.status) && (
            <CallToActionContainer>
              <CallToAction error={updateStatusError} onClick={handleMarkAsShippedOrder}>
                Mark as Fulfilled
              </CallToAction>
            </CallToActionContainer>
          )
        )}
        {order?.payment?.fail_reason && (
          <DrawerSection paddingT={20} paddingB={20}>
            <DrawerLabel>Payment Failure Reason</DrawerLabel>
            <DrawerSubLabel>{order?.payment?.fail_reason}</DrawerSubLabel>
          </DrawerSection>
        )}
      </DrawerFooter>
    </DrawerContainer>
  );
};

export default OrderDrawer;
