import React from 'react';
import styled from 'styled-components';
import Colors from '../../../../Colors';

export const DaycareReportNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DaycareReportNoteContainer = styled.div`
  padding: 16px;
  border-radius: 24px;
  &:hover {
    background-color: #ededed;
  }
  margin-top: 8px;
  border: 2px solid #e9e9e9;
`;

export const ReportNoteSubCategoryContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
`;

export const ReportNoteSubCategoryTitle = styled.span`
  font-weight: 800;
`;

export const TitleChip = styled.div`
  background-color: ${Colors.primary};
  border-radius: 4px;
  color: ${Colors.white};
  font-size: 12px;
  padding: 2px 4px;
`;

export const NoteTitleAction = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  margin-right: 4px;
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: 200px;
  background-color: ${Colors.subtitle};
  color: ${Colors.white};
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  bottom: 125%;
  right: 50%;

  ${Tooltip}:hover & {
    visibility: visible;
  }
`;

export const WithTooltip = ({ children, tooltipText, onClick }: { children: React.ReactNode; tooltipText: string; onClick?: () => void }) => (
  <Tooltip onClick={onClick}>
    {children}
    <TooltipText>{tooltipText}</TooltipText>
  </Tooltip>
);
