import { useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import Colors from '../../Colors';
import useSelectedState from '../../hooks/useSelectedState';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { DRAWER_IDS } from '../DrawerBar/types';
import { ExtensionBody, ExtensionContainer, TabContainer } from '../Pets/styled';
import TabsHeader from '../Shared/TabsHeader';
import UserCredits from './UserCredits';
import UserInvoices from './UserInvoices';
import { UserBookingsList, UserOrdersList, UserSubscriptionsList } from './UserOrdersList';

type UserExtensionProps = {
  userProfile: BranchAppUser;
};

export enum UserExtensionTabs {
  BOOKINGS = 'BOOKINGS',
  MEMBERSHIPS = 'MEMBERSHIPS',
  ORDERS = 'ORDERS',
  INVOICES = 'INVOICES',
  CREDIT = 'CREDIT'
}

const UserExtension: FC<UserExtensionProps> = ({ userProfile }) => {
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const userDrawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.USER_DRAWER);

  const otherData = userDrawerBar?.otherData || {};
  const defaultTab = otherData?.defaultTab || UserExtensionTabs.BOOKINGS;

  const [currentTab, setCurrentTab] = useSelectedState<typeof UserExtensionTabs>({ defaultTab, TABS_TYPES: UserExtensionTabs });

  const { data: { getBusUserProfile: { Branch: { branchBillingCycle: { billing_date_from = '', billing_date_to = '' } = {} } = {} } = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });
  const currentPeriod = { start: billing_date_from, end: billing_date_to };

  useEffect(() => {
    if (drawerBar?.otherData?.selectedTab) {
      setCurrentTab(drawerBar?.otherData?.selectedTab);
    }
  }, [drawerBar?.otherData?.selectedTab]);

  return (
    <ExtensionContainer>
      <TabsHeader tabs={Object.values(UserExtensionTabs)} selected={currentTab} setSelected={setCurrentTab} backgroundColor={Colors.white} />
      <ExtensionBody>
        <TabContainer>
          {currentTab === UserExtensionTabs.BOOKINGS && <UserBookingsList userProfile={userProfile} currentPeriod={currentPeriod} />}
          {currentTab === UserExtensionTabs.ORDERS && <UserOrdersList userProfile={userProfile} currentPeriod={currentPeriod} />}
          {currentTab === UserExtensionTabs.MEMBERSHIPS && <UserSubscriptionsList userProfile={userProfile} currentPeriod={currentPeriod} />}
          {currentTab === UserExtensionTabs.INVOICES && <UserInvoices userProfile={userProfile} />}
          {currentTab === UserExtensionTabs.CREDIT && <UserCredits userProfile={userProfile} />}
        </TabContainer>
      </ExtensionBody>
    </ExtensionContainer>
  );
};

export default UserExtension;
