import { useLazyQuery, useQuery } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback } from 'react';
import { sentenceCase } from 'sentence-case';
import useIcons from '../../hooks/useIcons';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { reduceAppointments } from '../../hooks/useReduceAppointments';
import { GetAppUserBranchCreditsByAppUserId, GetAppUserBranchCreditTransactionsByAppUserId, GetOrderItems } from '../../queries';
import { addDrawerBar, unsetDrawerBars } from '../../reactive/actions';
import { formatDateToDayMonthAndYear } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { ProductOrder } from '../../views/Orders/types';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchSummaryCountMode } from '../../views/Store/BranchSummary/styled';
import { RecordBody } from '../../views/styled';
import { ButtonsContainer } from '../BookingsDrawer/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import ModalDialog from '../Modal/ModalDialog';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import { BookingListItem, BookingsDivider, BookingsListCotainer, ListHeaderContainer, ListHeaderText, ListHeaderWrapper, ListView, UserBookingContainer } from './styled';
import UserCreditsUpdateModal from './UserCreditsUpdateModal';

type AppUserBranchCreditAction = {
  date: string;
  amount: string;
  by: { type: 'app' | 'bus'; id: string };
  type: 'CREDIT_ADDED' | 'CREDIT_REMOVED' | 'CREDIT_USED' | 'CREDIT_REFUNDED' | 'CREDIT_EXPIRED';
};
export type AppUserBranchCredit = {
  id: string;
  status: 'VALID' | 'EXPIRED';
  amount: number;
  AppUser: BranchAppUser;
  Branch: BranchAppUser;
  AppUserBranchCreditTransactions: AppUserBranchCreditTransaction[];
};
type AppUserBranchCreditTransaction = {
  id: string;
  status: 'APPLIED' | 'REVERSED';
  amount: number;
  Order: ProductOrder;
  AppUserBranchCredit: AppUserBranchCredit;
  expiry_date: string;
  action: AppUserBranchCreditAction;
};
const UserCredits = ({ userProfile }: { userProfile: BranchAppUser }) => {
  const icons = useIcons();
  const addIcon = icons?.add?.childImageSharp.gatsbyImageData;

  const { data: { getAppUserBranchCreditsByAppUserId: [credits] = [] } = {}, refetch } = useQuery<{ getAppUserBranchCreditsByAppUserId?: AppUserBranchCredit[] }>(GetAppUserBranchCreditsByAppUserId, {
    variables: {
      AppUserId: [userProfile.id]
    },
    fetchPolicy: 'cache-and-network'
  });
  const [getOrderItems] = useLazyQuery(GetOrderItems);

  const [[hasMoreCreditTransactions, setHasMoreCreditTransactions], queryResult] = usePaginatedQuery<AppUserBranchCreditTransaction[]>({
    query: GetAppUserBranchCreditTransactionsByAppUserId,
    limit: 10,
    otherVariables: {
      AppUserId: [userProfile.id]
    },
    otherParams: {
      skip: !credits
    }
  });

  const { data: { getAppUserBranchCreditTransactionsByAppUserId: transactions = [] } = {}, loading: loadingTransactions, fetchMore, refetch: refetchTransactions } = queryResult;

  const handleOrderClick = useCallback(async (order: ProductOrder) => {
    const {
      data: {
        getOrderItems: [orderItem]
      }
    } = await getOrderItems({
      variables: {
        OrderId: order.id
      }
    });
    const isBooking = orderItem.item.type === 'service';
    if (isBooking) {
      unsetDrawerBars();
      addDrawerBar({
        drawerId: DRAWER_IDS.BOOKING_DRAWER,
        recordData: reduceAppointments(orderItem.Appointments, {
          uniqueByOrderIdAndTimestamp: true,
          sortDesc: false
        }).flat()
      });
    } else {
      unsetDrawerBars();
      addDrawerBar({
        drawerId: DRAWER_IDS.ORDER_DRAWER,
        recordData: orderItem.Order
      });
    }
  }, []);

  const renderCreditTransaction = useCallback(
    (transaction: AppUserBranchCreditTransaction) => {
      const orderNumber = transaction.Order?.number;
      return (
        <BookingListItem key={transaction.id}>
          <UserBookingContainer onClick={() => handleOrderClick(transaction.Order)} clickable={!!orderNumber}>
            <RecordBody width="150" fontWeight={'400'}>
              {sentenceCase(transaction.action.type)}
              {transaction.action.type === 'CREDIT_USED' && ` - ${orderNumber}`}
            </RecordBody>
            <RecordBody width="auto" fontWeight={'800'}>
              {getBranchCurrencySymbol()}
              {transaction.action.amount}
            </RecordBody>
            <RecordBody width="150" fontWeight={'400'} flexEnd noMargin>
              {formatDateToDayMonthAndYear(new Date(transaction.action.date))}
            </RecordBody>
          </UserBookingContainer>
        </BookingListItem>
      );
    },
    [handleOrderClick]
  );

  return (
    <ListView>
      <ListHeaderContainer>
        <ListHeaderWrapper>
          <ListHeaderText>Credit</ListHeaderText>
          <ListHeaderText>
            {'▸'}
            {getBranchCurrencySymbol()}
            {credits?.amount || 0}
          </ListHeaderText>
          <ButtonsContainer style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <BranchSummaryCountMode
              style={{ display: 'flex', gap: 10, paddingBottom: 10 }}
              onClick={() =>
                ModalDialog.openModal({
                  content: () => <UserCreditsUpdateModal credits={credits} AppUserId={userProfile.id} />,
                  title: 'Add Credit',
                  onClose: () => {
                    refetch();
                    refetchTransactions();
                  }
                })
              }
            >
              <GatsbyImage image={addIcon} alt="Add Credit" />
            </BranchSummaryCountMode>
          </ButtonsContainer>
        </ListHeaderWrapper>
        <BookingsDivider />
      </ListHeaderContainer>
      <BookingsListCotainer flexOne>
        <InfiniteList
          hasMoreItems={hasMoreCreditTransactions}
          fetchMore={fetchMore}
          loading={loadingTransactions}
          setHasMoreItems={setHasMoreCreditTransactions}
          itemRenderer={renderCreditTransaction}
          list={transactions}
          offset={transactions?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

export default UserCredits;
