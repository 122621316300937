import { useLazyQuery } from '@apollo/client';
import React, { Fragment, useEffect } from 'react';
import { GetMultiOrderPreOrderSummaryForAppUser, GetPreOrderSummaryForAppUser, GetRecurringPreOrderSummaryForAppUser } from '../../../../queries';
import { FULL_DAYS, SUN_THREE_DAYS, formatOrdinals, toReadableDate } from '../../../../utils/dates';
import debounce from '../../../../utils/debounce';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { Description, PriceContainer, SummaryContainer, Title } from '../../../Calendar/Modals/styled';
import { ExpandableOverflow } from '../../Shared';
import { NewBookingModalFormProps, getProductsItems, getSelectedCustomizations } from './utils';

const PreOrderSummary = ({
  formOptions,
  selectedProductsResponse,
  groupsFullDates,
  selectedOrderGroups,
  preOrderSummaryForAppUserItems,
  productModalType,
  setPreOrderSummaryForAppUserItems,
  loadingStartOrder
}: NewBookingModalFormProps & { setPreOrderSummaryForAppUserItems: (items: { shouldRenderFees: boolean; preOrderSummaryForAppUser: any; loadingSummary: boolean }) => void }) => {
  const { watch } = formOptions;
  const { selectedProducts } = selectedProductsResponse;
  const fullDates = groupsFullDates?.[0] || [];
  const selectedProduct = selectedProducts[0];
  const watchedOrderGroups = watch('orderGroups');
  const productsItems = getProductsItems({ orderGroups: watchedOrderGroups, products: selectedProducts });

  const { isRecurring } = productsItems[0] || {};

  const selectedSlots = selectedOrderGroups[0]?.slots || [];
  const watchedPetsValue = watch('pets');
  const watchedValues = watch(['appUserId']);

  const isMultiOrder = selectedOrderGroups.length > 1;

  const hasCustomizations = Object.keys(selectedProduct?.customizations || {}).length;

  const { getPreOrderSummaryVariables, validateValues } = preOrderSummaryForAppUserItems;

  const [getPreOrderSummaryForAppUser, { data: { getPreOrderSummaryForAppUser: orderSummary = {} } = {}, loading: loadingPreOrderSummary }] = useLazyQuery(GetPreOrderSummaryForAppUser, {
    fetchPolicy: 'cache-and-network'
  });

  const [getRecurringPreOrderSummaryForAppUser, { data: { getRecurringPreOrderSummaryForAppUser: recurringOrderSummary = {} } = {}, loading: loadingRecurrence }] = useLazyQuery(
    GetRecurringPreOrderSummaryForAppUser,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const [getMultiOrderPreOrderSummaryForAppUser, { data: { getMultiOrderPreOrderSummaryForAppUser: multiOrderSummary = {} } = {}, loading: loadingMultiOrder }] = useLazyQuery(
    GetMultiOrderPreOrderSummaryForAppUser,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const preOrderSummaryForAppUser = isMultiOrder ? multiOrderSummary?.summariesTotal : isRecurring ? recurringOrderSummary?.sumamriesTotal : orderSummary;

  const loadingSummary = loadingMultiOrder || loadingRecurrence || loadingPreOrderSummary;

  const multiOrderSummaries = multiOrderSummary?.summaries || [];
  const recurringSummaries = recurringOrderSummary?.summaries || [];

  const recurringSummaryTimestamps = recurringOrderSummary?.timestamps || [];

  useEffect(() => {
    const variables = getPreOrderSummaryVariables();
    debounce(() => {
      if (!variables || loadingStartOrder) {
        return;
      }

      if (isMultiOrder) {
        getMultiOrderPreOrderSummaryForAppUser({ variables });
        return;
      }

      if (isRecurring) {
        getRecurringPreOrderSummaryForAppUser({ variables });
        return;
      }

      getPreOrderSummaryForAppUser({ variables });
    });
  }, [watchedPetsValue, groupsFullDates, isRecurring, isMultiOrder]);

  const selectedCustomizations = getSelectedCustomizations({ customizations: watchedOrderGroups?.[0]?.customizations || {}, customizationsMap: selectedProduct?.customizations }) || {
    fields: [],
    quantity: 0
  };

  const finalDiscount = watchedPetsValue.length || productModalType ? Number(preOrderSummaryForAppUser?.discount) : 0;
  const isDiscounted = !!finalDiscount;
  const total = Number(preOrderSummaryForAppUser?.total);
  const finalPriceWithDelivery = Number(preOrderSummaryForAppUser?.finalPrices?.finalPriceWithDelivery);
  const priceSummary = preOrderSummaryForAppUser?.productsWithTotals?.[0]?.productTotals?.priceSummary || '';
  const creditToBeUsed = Number(preOrderSummaryForAppUser?.creditToBeUsed || 0).toFixed(2);
  const totalString = preOrderSummaryForAppUser?.total !== undefined ? `${getBranchCurrencySymbol()}${total.toFixed(2)}` : 'Calculating...';

  const isFree = total < 0.5;
  const hasSelectedProduct = !!selectedProduct;
  const hasSelectedPets = !!watchedPetsValue.length;
  const hasSelectedAppUser = !!watchedValues?.appUserId;
  const selectedAllCustomizations = Object.values(selectedCustomizations.fields).every(field => !!field.title);
  const shouldRenderFees = (!!hasCustomizations ? selectedAllCustomizations : true) && hasSelectedProduct && (productModalType ? hasSelectedAppUser : hasSelectedPets) && validateValues();

  const dateDescription = fullDates.map((fullDate, i) => toReadableDate(fullDate.timestamp.fullDate, { noTime: true }) + (fullDates[i + 1] ? '\n' : ''));
  const selectedDays = selectedSlots?.[0]?.days || [];
  const selectedDaysIndeces = selectedDays.map(day => SUN_THREE_DAYS.indexOf(day));
  const selectedDaysDescription = selectedDaysIndeces.map(index => FULL_DAYS[index]).join(', ');
  const slotTime = selectedSlots?.[0]?.time;
  const slotRepeatition = selectedSlots?.[0]?.repetition;
  const repeatition = `${slotTime}, repeated for ${slotRepeatition?.repeat} Weeks`;
  const totalTimestaps = !loadingRecurrence ? `${recurringSummaryTimestamps?.length || 0} slot(s) is available from your starting date.` : 'Calculating...';
  const recurringDateDescription = `Starting, ${dateDescription}`;

  const multiOrderTotals = [...((isMultiOrder && multiOrderSummaries) || (isRecurring && recurringSummaries) || [])].map((summary, index) => {
    const total = Number(summary?.total);
    const finalPriceWithDelivery = `${getBranchCurrencySymbol()}${(Number(summary?.finalPrices?.finalPriceWithDelivery) || 0).toFixed(2)}`;
    const orderSummaryDiscount = Number(summary?.discount);
    const isDiscounted = orderSummaryDiscount > 0;
    const totalString = summary?.total !== undefined ? `${getBranchCurrencySymbol()}${total.toFixed(2)}` : '';
    const isFree = total < 0.5;
    const recurringTimestamp = recurringSummaryTimestamps?.[index];
    return (
      <Fragment key={index}>
        <PriceContainer>
          <Description>{recurringTimestamp ? toReadableDate(recurringTimestamp, { noTime: true, withDay: true }) : `${formatOrdinals(index + 1)} Booking:`}:</Description>
          {!isFree && (
            <>
              <Description crossed={isDiscounted}>{totalString}</Description>
              {isDiscounted && <Description bold>{` ${finalPriceWithDelivery}`}</Description>}
            </>
          )}
          {isFree && <Description>Free</Description>}
        </PriceContainer>
      </Fragment>
    );
  });

  const renderTotal = () => {
    return (
      <>
        {!isFree && !isMultiOrder && (
          <>
            {!isRecurring && !productModalType && (
              <Description>
                Original Price: {getBranchCurrencySymbol()}
                {selectedProduct?.price}
              </Description>
            )}
            {!productModalType &&
              [selectedCustomizations].map(customization =>
                Object.keys(customization.fields || {}).map(customKey => (
                  <Title key={customKey}>{`(${customization.fields[customKey]?.title}): ${getBranchCurrencySymbol()}${Number(customization.fields[customKey]?.price)}`}</Title>
                ))
              )}

            {isRecurring && (
              <>
                <Description>{selectedDaysDescription}</Description>
                <Description>{repeatition}</Description>
                <Description>{recurringDateDescription}</Description>
                <Description bold>{totalTimestaps}</Description>
              </>
            )}
            {!isRecurring && (
              <>
                <PriceContainer>
                  Total:
                  <Description crossed={isDiscounted}>{totalString}</Description>
                  {!productModalType && <Description>{priceSummary}</Description>}
                </PriceContainer>
                {isDiscounted && (productModalType ? true : !!watchedPetsValue.length) && (
                  <>
                    <Title>Discount applied</Title>
                    <Description>{String(finalPriceWithDelivery) ? `${getBranchCurrencySymbol()}${finalPriceWithDelivery.toFixed(2)}` : 'calculating...'}</Description>
                  </>
                )}
                {Number(creditToBeUsed) > 0 && (
                  <PriceContainer>
                    Credit to be used:
                    <Description>{creditToBeUsed}</Description>
                  </PriceContainer>
                )}
              </>
            )}
          </>
        )}
        {loadingMultiOrder && <Description>Calculating...</Description>}
        {(isMultiOrder || isRecurring) && <ExpandableOverflow>{multiOrderTotals}</ExpandableOverflow>}

        {isFree && <PriceContainer>Free</PriceContainer>}
      </>
    );
  };

  useEffect(() => {
    setPreOrderSummaryForAppUserItems({
      shouldRenderFees,
      preOrderSummaryForAppUser,
      loadingSummary
    });
  }, [preOrderSummaryForAppUser?.total, loadingSummary, shouldRenderFees]);

  return (
    <>
      <SummaryContainer>
        <Title>Fees</Title>
        {shouldRenderFees && renderTotal()}
      </SummaryContainer>
    </>
  );
};

export default PreOrderSummary;
