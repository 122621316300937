import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ModalDialog from '../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../components/Modal/styled';
import { FormButtonsContainer, FormCheckbox, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../components/Shared/Forms/Forms';
import { Container } from '../../components/Shared/Shared';
import { UpdateAppUserBranchCredit } from '../../queries';
import { AppUserBranchCredit } from './UserCredits';

type UserCreditsUpdateModalProps = {
  credits?: AppUserBranchCredit;
  AppUserId: string;
};

const UserCreditsUpdateModal: FC<UserCreditsUpdateModalProps> = ({ credits, AppUserId }) => {
  const [updateAppUserBranchCredit, { loading, data, error }] = useMutation(UpdateAppUserBranchCredit);

  const { control, handleSubmit } = useForm();

  const expirySet: string[] = useWatch({ control, name: 'expiry_date_set', defaultValue: [] });

  const onSubmit = handleSubmit(async values => {
    updateAppUserBranchCredit({
      variables: {
        AppUserId: AppUserId,
        // status: values.status,
        expiry_date: values.expiry_date,
        new_amount: Number(values.amount).toFixed(2)
      }
    });
  });

  useEffect(() => {
    if (data) {
      ModalDialog.closeModal();
    }
  }, [data]);

  return (
    <>
      <ModalBody>
        <Container width={600}>
          <InputsWrapper>
            {/* <WideInputGroup>
              <FormLabel>Status</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    options={[
                      { label: 'Valid', value: 'VALID' },
                      { label: 'Invalid', value: 'INVALID' }
                    ]}
                    value={{ label: value === 'VALID' ? 'Valid' : 'Invalid', value }}
                    onChange={option => onChange(option?.value)}
                    theme={selectTheme}
                  />
                )}
                control={control}
                name={'status'}
                defaultValue={credits?.status || 'VALID'}
              />
            </WideInputGroup> */}
            <WideInputGroup marginTop={10}>
              <FormLabel>Amount</FormLabel>
              <Controller
                as={<FormInput type={'number'} height={32} fontSize={16} />}
                control={control}
                name={'amount'}
                defaultValue={0}
                rules={{ validate: value => Number(value) != 0 && Number(value) + Number(credits?.amount || 0) >= 0 }}
              />
            </WideInputGroup>
            <WideInputGroup marginTop={10}>
              <Controller
                render={({ onChange, value }) => (
                  <FormCheckbox
                    itemsArray={[
                      {
                        id: 'expiry_date_set',
                        name: 'Expiry Date'
                      }
                    ]}
                    onChange={onChange}
                    value={value || []}
                  />
                )}
                control={control}
                name={'expiry_date_set'}
                defaultValue={[]}
              />
              <br />
              {expirySet.includes('expiry_date_set') && (
                <Controller as={<FormInput type={'date'} height={32} fontSize={16} />} control={control} name={'expiry_date'} rules={{ required: true, min: new Date().toISOString().split('T')[0] }} />
              )}
            </WideInputGroup>
          </InputsWrapper>
        </Container>
        {error && <FormError>{error.message}</FormError>}
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!error} loading={loading} onClick={() => onSubmit()}>
            Add credit
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default UserCreditsUpdateModal;
