import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FlexColumnCenterContainer } from '../../../../components/Pets/styled';
import { BusUserProfile } from '../../../../components/Profile/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, SectionLabel, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { Container, FlexRow } from '../../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../../components/Shared/Spinner';
import useIcons from '../../../../hooks/useIcons';
import usePhotoUpload, { useDeletePhotos } from '../../../../hooks/usePhotoUpload';
import { GetBusUserProfile, NoteAdd, NoteEdit, SuggestNewNote } from '../../../../queries';
import { ActionBtn } from '../../../Store/styled';
import NotePhotos from '../NotePhotos';
import NotesMarkupEditor from '../NotesMarkupEditor';
import { NoteTitleAction, TitleChip, WithTooltip } from './styled';

const POO_SCALES = ['normal', 'constipated', 'mild_diarrhoea', 'sever_diarrhoea'];

export enum NOTE_ACTION_TYPES {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

const DaycareReportNoteModal: FC<{
  selectedReport: Record<string, any>;
  selectedNote?: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
}> = ({ selectedReport, selectedNote, refetchNotes }) => {
  const { data: { getBusUserProfile: { Branch: { BusUsers = [] } = {} } = {} } = {} } = useQuery<{
    getBusUserProfile: BusUserProfile;
  }>(GetBusUserProfile);
  const [handleAddNote, { loading: loadingAddNote, error: errorNoteAdd }] = useMutation(NoteAdd);
  const [handleEditNote, { loading: loadingEditNote, error: errorNoteEdit }] = useMutation(NoteEdit);
  const [suggestNewNote, { loading: loadingSuggestNewNote, error: errorSuggestNewNote }] = useMutation(SuggestNewNote);

  const noteIdRef = useRef<string>('');
  const photosRef = useRef<Record<string, any>>({});

  const [defaultNote, setDefaultNote] = useState(selectedNote?.body?.notes?.notes || { details: '', details_html: '' });

  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const { control, handleSubmit } = useForm();

  const loading = loadingAddNote || loadingEditNote || loadingUploadPhotos;
  const error = !!errorNoteAdd?.message || !!errorNoteEdit?.message || !!errorUploadPhotos;

  const handleSave = handleSubmit(async form => {
    if (selectedNote) {
      noteIdRef.current = selectedNote.id;
    }

    if (!selectedNote) {
      const { data: { noteAdd: addedNote = {} } = {} } = await handleAddNote({
        variables: {
          body: form,
          timestamp: new Date(),
          daycareReportId: selectedReport.id
        }
      });
      noteIdRef.current = addedNote.id;
    }
    const photos = await photosRef.current?.getPhotos();
    const attachments = await photosRef.current?.getAttachments();

    await handleEditNote({
      variables: {
        id: noteIdRef.current,
        body: {
          ...form,
          photos: {
            primary: photos
          },
          attachments: {
            primary: attachments
          }
        },
        timestamp: new Date()
      }
    });

    ModalDialog.closeModal();
    refetchNotes?.();
  });

  const handleSuggestNote = useCallback(async () => {
    // const photos = await photosRef.current?.getPhotos();

    suggestNewNote({
      variables: {
        PetRecordId: selectedReport.PetRecord.id,
        type: 'DaycareReport',
        photos: []
      }
    }).then(
      ({
        data: {
          suggestNewNote: { text }
        }
      }) => {
        control.setValue('notes.notes.details', text);
        control.setValue('notes.notes.details_html', text);
        let i = 0;
        const interval = setInterval(() => {
          i += 10;
          setDefaultNote({ details: text.slice(0, i), details_html: text.slice(0, i) });
          if (i > text.length) {
            clearInterval(interval);
          }
        }, 100);
      }
    );
  }, []);

  return (
    <>
      <ModalBody>
        <Container width={500}>
          <NotePhotos uploadPhotos={uploadPhotos} deletePhotos={deletePhotos} ref={photosRef} selectedNote={selectedNote} noteIdRef={noteIdRef} />
          <InputsWrapper>
            <FlexColumnCenterContainer>
              <SectionLabel>Day Summary</SectionLabel>
              <FormLabel>Daycare</FormLabel>
            </FlexColumnCenterContainer>
            <WideInputGroup>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect
                    height={48}
                    fontSize={16}
                    name={'daySummary.carer.details'}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                    value={value || ''}
                  >
                    <option value={''}>-- No Staff Member --</option>
                    {BusUsers.map(({ name }, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'daySummary.carer.details'}
                defaultValue={selectedNote?.body?.daySummary?.carer?.details || ''}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Walks</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`daySummary.walks.details`} control={control} defaultValue={selectedNote?.body?.daySummary?.walks?.details || ''} />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Meals</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`routine.meals.details`} control={control} defaultValue={selectedNote?.body?.routine?.meals?.details || ''} />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Poos</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`routine.poos.details`} control={control} defaultValue={selectedNote?.body?.routine?.poos?.details || ''} />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Poo Scale</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect name={'daySummary.pooScale.details'} onChange={e => onChange(e.target.value)} value={value} height={48} fontSize={16}>
                    {POO_SCALES.map(scale => (
                      <option key={scale} value={scale}>
                        {sentenceCase(scale)}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'daySummary.pooScale.details'}
                defaultValue={selectedNote?.body?.daySummary?.pooScale?.details || ''}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Sleep/Rest</FormLabel>
              <Controller as={<FormInput type={'number'} />} name={`routine.sleep.details`} control={control} defaultValue={selectedNote?.body?.routine?.sleep?.details || ''} />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Playmates</FormLabel>
              <Controller as={<FormInput />} name={`daySummary.playmates.details`} control={control} defaultValue={selectedNote?.body?.daySummary?.playmates?.details || ''} />
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Notes</SectionLabel>
            <WideInputGroup>
              <FlexRow justify="space-between">
                <FormLabel>Notes</FormLabel>
                <FlexRow>
                  {loadingSuggestNewNote ? (
                    <ActivityIndicator size={20} />
                  ) : (
                    <WithTooltip
                      onClick={handleSuggestNote}
                      tooltipText={`This tool is in its alpha stage and may not provide the best outputs. It can make mistakes, Please use with caution and verify the results.`}
                    >
                      <NoteTitleAction>Suggest Note</NoteTitleAction>
                    </WithTooltip>
                  )}
                  <TitleChip>Alpha</TitleChip>
                </FlexRow>
                {errorSuggestNewNote && <FormError>{errorSuggestNewNote.message}</FormError>}
              </FlexRow>
              <NotesMarkupEditor control={control} defaultValue={defaultNote} name="notes.notes" />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={handleSave}>
            Save note
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const DaycareReportNoteAction: FC<{
  type: NOTE_ACTION_TYPES;
  note?: Record<string, any>;
  selectedReport: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  autoOpenModal?: boolean;
}> = ({ type, note, selectedReport, refetchNotes, autoOpenModal }) => {
  const icons = useIcons();
  const icon = type === NOTE_ACTION_TYPES.ADD ? icons.add.childImageSharp.gatsbyImageData.images.fallback.src : icons.edit.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <DaycareReportNoteModal selectedNote={note} selectedReport={selectedReport} refetchNotes={refetchNotes} />,
        title: `${sentenceCase(type)} note`
      }),
    [note, selectedReport, refetchNotes, type]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={icon} onClick={showModal} />;
};

export default DaycareReportNoteAction;
